<!-- =========================================================================================
    File Name: LockScreen.vue
    Description: Lock Screen Page
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/lock-screen.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Verifying!</h4>
                                </div>
                                <div class="flex justify-between flex-wrap">
                                  {{text}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import moduleTodo from '@/store/todo/moduleTodo.js'
import amplify from '@/amplify/auth.js'
import axios from '@/axios'

import router from '@/router'

export default {
  data () {
    return {
      text: 'Please Wait...'
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.firmName !== ''
    }
  },
  created () {
    this.$vs.loading()

    if(this.parseQuery()) {
      axios.post('/trello/callback' + window.location.search, this.parseQuery())
        .then(function (response) {
          window.close();
        })
        .catch(function (error) {
          window.close();
        });
    } else {
      setTimeout(function () {
        window.close();
      }, 5000);
    }
  },
  methods: {
    parseQuery() {
        let queryString = window.location.search
        if(queryString) {
          let query = {};
          let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
          for (let i = 0; i < pairs.length; i++) {
              let pair = pairs[i].split('=');
              query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
          }
          return query;
        }

        return false;
    },
    connectTrello() {
    }
  }
}
</script>
